@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@import 'react-date-range/dist/styles.css'; 
@import 'react-date-range/dist/theme/default.css';


.form-check-input:checked {
  background-color: rgba(0, 69, 141, 0.8) !important;
  border-color: rgba(0, 69, 141, 0.8) !important;
}

.Toastify__progress-bar--info {
  background-color: rgb(0 69 141 / 80%) !important;
}

body {
  font-family: Poppins, Helvetica, "sans-serif";
}

/* @Deprecate */
.pacer {
  margin-top: 20px;
}
a:hover {
  text-decoration: none;
  color: inherit;
}

a {
  color: inherit;
}
.MuiCircularProgress-svg {
  color: #0c74bc;
}
.MuiAppBar-colorSecondary {
  background-color: #0c74bc;
}

/* .makeStyles-drawerOpen-41{

} */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #eee;
  border-radius: 6px;
}
.overflow-y {
  overflow-y: scroll;
}
/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: rgb(142, 136, 136);
}
::-webkit-scrollbar:horizontal {
  height: 8px;
  background: #eee;
}
::-webkit-scrollbar-thumb:horizontal {
  background: rgb(142, 136, 136);

  border-radius: 6px;
}
a:hover {
  text-decoration: none;
  color: inherit;
}

a {
  color: inherit;
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: #0c74bc !important;
  border-color: #0c74bc !important;
}

body {
  background: #f0f0fa !important;
}
.MuiTabs-indicator {
  background-color: white !important;
}

.MuiAutocomplete-inputRoot {
  padding: 0 !important;
}

.flex {
  display: flex;
}
.mt-35 {
  margin-top: 75px;
}
.hover-cursor:hover {
  cursor: pointer;
}
.flex-2 {
  flex: 2;
}

.disabled-button {
  pointer-events: none;
  opacity: 0.4;
}

.toast-message {
  background-color: rgb(0 69 141 / 80%) ;
  color: #ffffff !important;
  font-size: 1rem;
  padding: 1rem 0.5rem;
}

.toast-error {
  background-color: #bb2d3b !important;
  color: #ffffff !important;
  font-size: 1rem;
  padding: 1rem 0.5rem;
}
.Toastify__close-button {
  color: #ffffff !important;
  opacity: 1 !important;
}
